// src/components/TotalMints.js
import React, { useEffect, useState } from 'react';
import { formatEther, Contract } from 'ethers';
import '../styles.css';
import DAO3_ABI from '../contracts/ETCMC-DAO3-ABI.json';

// same address
const dao3Address = "0x911211d3ebB4C61423d11a3bB6484d05333C576b";

const TotalMints = ({ readOnlyProvider }) => {
  const [mintPrice, setMintPrice] = useState(null);
  const [totalMinted, setTotalMinted] = useState(null);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const fetchMintData = async () => {
      if (!readOnlyProvider) return;
      try {
        // 1) create a read-only contract with the readOnlyProvider
        const readOnlyContract = new Contract(dao3Address, DAO3_ABI, readOnlyProvider);

        // 2) call the read methods
        const price = await readOnlyContract.mintPrice();
        const total = await readOnlyContract.totalMinted();

        setMintPrice(formatEther(price)); // from Wei to ETC
        setTotalMinted(total.toString());
      } catch (error) {
        console.error('Failed to fetch mint data:', error);
      }
    };

    fetchMintData();
  }, [readOnlyProvider]);

  if (!isVisible) return null;

  return (
    <div className="total-mints">
      <button className="close-btn" onClick={() => setIsVisible(false)}>
        X
      </button>
      <h2>Total Mints</h2>
      <p>Mint Price: {mintPrice} ETC</p>
      <p>Total Minted: {totalMinted}</p>
    </div>
  );
};

export default TotalMints;



