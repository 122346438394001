// src/components/MintSection.js

import React, { useState, useEffect } from 'react';
import { Contract, parseEther, Interface } from 'ethers';
import NFTModal from './NFTModal';
import Notification from './Notification';
import DAO3_ABI from '../contracts/ETCMC-DAO3-ABI.json';

const dao3Address = "0x911211d3ebB4C61423d11a3bB6484d05333C576b";

/**
 * Props:
 *  - readOnlyProvider: an ethers.JsonRpcProvider or similar for ETC
 *  - signer: the phone's signer for actual transactions
 *  - contract: the contract instance with the phone's signer (if desired),
 *    or we'll create it inline for the transaction
 */
const MintSection = ({ readOnlyProvider, signer, contract: txContract }) => {
  const [numberToMint, setNumberToMint] = useState(1);
  const [minting, setMinting] = useState(false);
  const [mintedNFTs, setMintedNFTs] = useState([]);
  const [currentNFTIndex, setCurrentNFTIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [notification, setNotification] = useState({
    visible: false,
    title: '',
    message: '',
    isLoading: false,
    showCloseButton: false
  });

  // We'll store the read-only mintPrice separately so we never call mintPrice on the phone's signer
  const [mintPrice, setMintPrice] = useState(null);

  useEffect(() => {
    // 1) Use the readOnlyProvider for the read call if available
    if (!readOnlyProvider) return;

    async function fetchMintPrice() {
      try {
        // Create a read-only contract
        const readOnlyContract = new Contract(dao3Address, DAO3_ABI, readOnlyProvider);
        // Call the read method
        const priceBN = await readOnlyContract.mintPrice();
        setMintPrice(priceBN);
      } catch (err) {
        console.error("Error fetching mint price (read-only):", err);
      }
    }

    fetchMintPrice();
  }, [readOnlyProvider]);

  // 2) Helpers for notifications
  const showNotification = (title, message, isLoading = false, showCloseButton = false) => {
    setNotification({ visible: true, title, message, isLoading, showCloseButton });
  };

  const hideNotification = () => {
    setNotification({
      visible: false,
      title: '',
      message: '',
      isLoading: false,
      showCloseButton: false
    });
  };

  // 3) The actual mint transaction uses the phone's signer
  const mintNFTs = async () => {
    if (minting) return;
    if (!signer) {
      showNotification('Error', 'No wallet connected or signer is null.', false, true);
      return;
    }
    if (!mintPrice) {
      showNotification('Error', 'Mint price not loaded yet from read-only provider.', false, true);
      return;
    }

    setMinting(true);

    try {
      // Build or reuse the signer-based contract for TX
      let contractInstance = txContract;
      if (!contractInstance) {
        contractInstance = new Contract(dao3Address, DAO3_ABI, signer);
      }

      // Calculate value from the read-only mintPrice
      const costPerNft = parseFloat(mintPrice) / 1e18;
      const totalCost = costPerNft * numberToMint;
      const value = parseEther(totalCost.toString());

      // Possibly fetch fee data from phone's signer provider
      const feeData = await signer.provider.getFeeData();
      console.log('[MintSection TX] feeData:', feeData);

      // fallback logic for gas
      const gasSettings = feeData.maxFeePerGas && feeData.maxPriorityFeePerGas
        ? {
            maxFeePerGas: feeData.maxFeePerGas,
            maxPriorityFeePerGas: feeData.maxPriorityFeePerGas
          }
        : { gasPrice: feeData.gasPrice };

      let gasPrice;
      if (gasSettings.gasPrice) {
        gasPrice = gasSettings.gasPrice * 130n / 100n;
      } else {
        gasPrice = feeData.maxFeePerGas * 130n / 100n;
      }

      console.log('[MintSection TX] final gasPrice:', gasPrice.toString());

      showNotification('Minting NFT...', 'Please wait while your NFTs are being minted.', true);

      // 4) Now do the phone-based transaction
      const tx = await contractInstance.mint(numberToMint, {
        value,
        gasPrice
      });
      console.log('[MintSection] Transaction sent:', tx);

      // Wait for confirmation
      const receipt = await tx.wait();
      console.log('[MintSection] Transaction receipt:', receipt);
      hideNotification();

      if (receipt.status === 1) {
        showNotification('Mint Successful!', `You minted ${numberToMint} NFT(s). Fetching NFT data...`, true);

        // Fetch minted NFT data from logs
        const mintedList = await extractMintedNFTs(receipt, contractInstance);
        setMintedNFTs(mintedList);
        setCurrentNFTIndex(0);
        hideNotification();

        if (mintedList.length > 0) {
          setShowModal(true);
        } else {
          showNotification('Success', 'NFTs minted, but no NFT data found.', false, true);
        }
      } else {
        showNotification('Transaction Failed', 'Please raise the gas limit and try again.', false, true);
      }
    } catch (error) {
      console.error('Error in mintNFTs:', error);
      hideNotification();
      showNotification('Transaction Failed', error.message || 'Please try again.', false, true);
    } finally {
      setMinting(false);
    }
  };

  // 5) Parse the minted events
  const extractMintedNFTs = async (receipt, contractInstance) => {
    const mintedTokens = [];
    const iface = new Interface(DAO3_ABI);

    for (const log of receipt.logs) {
      try {
        const parsedLog = iface.parseLog(log);
        if (parsedLog.name === 'Mint') {
          const tokenId = parsedLog.args.tokenId.toString();
          const tokenURI = await contractInstance.tokenURI(tokenId);
          const metadata = await fetch(tokenURI).then((res) => res.json());

          mintedTokens.push({
            tokenId,
            name: metadata.name,
            imageUrl: metadata.image,
            contractAddress: dao3Address
          });
        }
      } catch (err) {
        // not a Mint event
      }
    }
    return mintedTokens;
  };

  // 6) Modal navigation
  const handleNext = () => {
    if (currentNFTIndex < mintedNFTs.length - 1) {
      setCurrentNFTIndex(currentNFTIndex + 1);
    }
  };

  const handleBack = () => {
    if (currentNFTIndex > 0) {
      setCurrentNFTIndex(currentNFTIndex - 1);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="mint-section">
      {/* Possibly display the read-only mintPrice or a "Load Price" button */}
      {mintPrice ? (
        <p>Mint Price : {parseFloat(mintPrice) / 1e18} ETC</p>
      ) : (
        <p>No Mint Price loaded yet</p>
      )}

      <input
        type="number"
        min="1"
        value={numberToMint}
        onChange={(e) => setNumberToMint(e.target.value)}
        placeholder="Number of NFTs"
      />
      <button
        className="custom-btn"
        onClick={mintNFTs}
        disabled={minting || !mintPrice}
      >
        {minting ? 'Minting...' : 'MINT'}
      </button>

      {notification.visible && (
        <Notification
          title={notification.title}
          message={notification.message}
          isLoading={notification.isLoading}
          onClose={hideNotification}
          showCloseButton={notification.showCloseButton}
        />
      )}

      {showModal && (
        <NFTModal
          nft={mintedNFTs[currentNFTIndex]}
          isFirstNFT={currentNFTIndex === 0}
          isLastNFT={currentNFTIndex === mintedNFTs.length - 1}
          onNext={handleNext}
          onBack={handleBack}
          onClose={handleClose}
        />
      )}
    </div>
  );
};

export default MintSection;












































