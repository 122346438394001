// src/components/Header.js
import React, { useState } from 'react';
import logo from '../images/ETCMC-LOGO1.png';
import MetaMaskConnectButton from './MetaMaskConnectButton';
import ETCWalletConnectButton from './ETCWalletConnectButton';
import ViewNFTGallery from './ViewNFTGallery';
import NFTBreakdown from './NFTBreakdown';

const Header = ({ setWalletConnected, setSigner, setContract, signer }) => {
  const [showGallery, setShowGallery] = useState(false);
  const [showNFTBreakdown, setShowNFTBreakdown] = useState(false);

  return (
    <header className="header">
      <div className="header-left">
        <img src={logo} alt="ETCMC Logo" />

        {/* MetaMask button */}
        <MetaMaskConnectButton
          setWalletConnected={setWalletConnected}
          setSigner={setSigner}
          setContract={setContract}
        />

        {/* ETC WalletConnect button */}
        <ETCWalletConnectButton
          setWalletConnected={setWalletConnected}
          setSigner={setSigner}
          setContract={setContract}
        />
      </div>

      <div className="header-right">
        <a
          href="https://etcmc-marketplace.netlify.app/marketplace"
          className="nav-link"
          target="_blank"
          rel="noreferrer"
        >
          MARKETPLACE
        </a>
        <a
          href="https://dao3-furnace-weekly-spin.netlify.app/"
          className="nav-link"
          target="_blank"
          rel="noreferrer"
        >
          WEEKLY SPINNER
        </a>
        <a
          href="#"
          className="nav-link"
          onClick={() => setShowNFTBreakdown(true)}
        >
          NFT BREAKDOWN
        </a>
      </div>

      {showGallery && (
        <ViewNFTGallery signer={signer} onClose={() => setShowGallery(false)} />
      )}
      {showNFTBreakdown && (
        <NFTBreakdown onClose={() => setShowNFTBreakdown(false)} />
      )}
    </header>
  );
};

export default Header;












