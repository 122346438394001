// src/App.js

import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Header from './components/Header';
import Footer from './components/Footer';
import NFTGallery from './components/NFTGallery';
import MintSection from './components/MintSection';
import UpgradeSection from './components/UpgradeSection';
import DoubleMintSection from './components/DoubleMintSection';
import TotalMints from './components/TotalMints';
import UpgradeFees from './components/UpgradeFees';
import './styles.css';

import FIRE3 from './images/FIRE3.png';
import FIRE4 from './images/FIRE4.png';
import DAO3_ABI from './contracts/ETCMC-DAO3-ABI.json';

const dao3Address = "0x911211d3ebB4C61423d11a3bB6484d05333C576b";

// --1) Create a read-only provider for ETC data
const readOnlyProvider = new ethers.JsonRpcProvider("https://etc.rivet.link");
// ^ or use another endpoint like "https://etc.phyro.io"

const App = () => {
  const [walletConnected, setWalletConnected] = useState(false);
  const [signer, setSigner] = useState(null);
  const [contract, setContract] = useState(null);

  useEffect(() => {
    // If user connected & we have a signer, let's create a contract for tx.
    if (walletConnected && signer) {
      const contractInstance = new ethers.Contract(dao3Address, DAO3_ABI, signer);
      setContract(contractInstance);
    }
  }, [walletConnected, signer]);

  return (
    <div className="App">
      <Header
        setWalletConnected={setWalletConnected}
        setSigner={setSigner}
        setContract={setContract}
        signer={signer}
      />

      <div className="app-title">
        <h1>ETCMC DAO3-FURNACE</h1>
      </div>

      {walletConnected ? (
        <div className="container">
          {/* 
            For actual NFT reading (like owned tokens), 
            you might still do that in NFTGallery, but if it uses the phone's signer, 
            that's okay if it doesn't break. 
            If it does break, you can also do read-only there. 
          */}
          <NFTGallery signer={signer} />

          <div className="action-buttons">
            {/* Use phone's signer for actual TX calls */}
            <UpgradeSection signer={signer} />
            <MintSection
              readOnlyProvider={readOnlyProvider} 
              signer={signer} 
              contract={contract}
            />

            <DoubleMintSection signer={signer} />
          </div>

          {/* 2) Pass readOnlyProvider so display components never use phone's signer */}
          <TotalMints readOnlyProvider={readOnlyProvider} />
          <UpgradeFees readOnlyProvider={readOnlyProvider} />
        </div>
      ) : (
        <p>Please connect your wallet to view and interact with NFTs.</p>
      )}

      <Footer />
      <img src={FIRE3} alt="Fire" className="fire-image" />
      <img src={FIRE4} alt="Flipped Fire" className="flipped-fire-image" />
    </div>
  );
};

export default App;






























