// src/components/DoubleMintSection.js
import React, { useState } from 'react';
import { Contract, Interface } from 'ethers';
import NFTModal from './NFTModal';
import Notification from './Notification';
import DAO3_ABI from '../contracts/ETCMC-DAO3-ABI.json';

const dao3Address = "0x911211d3ebB4C61423d11a3bB6484d05333C576b";

const DoubleMintSection = ({ signer }) => {
  const [contractAddress, setContractAddress] = useState(dao3Address);
  const [tokenId, setTokenId] = useState('');
  const [minting, setMinting] = useState(false);
  const [mintedNFTs, setMintedNFTs] = useState([]);
  const [currentNFTIndex, setCurrentNFTIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [notification, setNotification] = useState({
    visible: false,
    title: '',
    message: '',
    isLoading: false,
    showCloseButton: false
  });

  const showNotification = (title, message, isLoading = false, showCloseButton = false) => {
    setNotification({ visible: true, title, message, isLoading, showCloseButton });
  };

  const hideNotification = () => {
    setNotification({
      visible: false,
      title: '',
      message: '',
      isLoading: false,
      showCloseButton: false
    });
  };

  const extractMintedNFTs = async (receipt, nftContract) => {
    const minted = [];
    const iface = new Interface(DAO3_ABI);

    for (const log of receipt.logs) {
      try {
        const parsedLog = iface.parseLog(log);
        if (parsedLog.name === 'Transfer') {
          const newTokenId = parsedLog.args.tokenId.toString();
          const tokenURI = await nftContract.tokenURI(newTokenId);
          const metadata = await fetch(tokenURI).then((res) => res.json());

          minted.push({
            tokenId: newTokenId,
            name: metadata.name,
            imageUrl: metadata.image,
            contractAddress: dao3Address
          });
        }
      } catch (err) {
        // not a Transfer event
      }
    }
    return minted;
  };

  const claimDoubleMint = async () => {
    if (minting) return;
    if (!signer) {
      showNotification('Error', 'No wallet connected or signer is null.', false, true);
      return;
    }

    setMinting(true);

    try {
      const nftContract = new Contract(contractAddress, DAO3_ABI, signer);

      showNotification('Approving NFT Transfer...', 'Approving transfer of your NFT.', true);
      let tx = await nftContract.approve(dao3Address, tokenId);
      await tx.wait();
      hideNotification();
      showNotification('Approval Successful', 'Transfer approved. Please sign the mint transaction.', false, true);

      const feeData = await signer.provider.getFeeData();
      console.log('[DoubleMint] feeData:', feeData);

      const gasSettings = feeData.maxFeePerGas && feeData.maxPriorityFeePerGas
        ? {
            maxFeePerGas: feeData.maxFeePerGas,
            maxPriorityFeePerGas: feeData.maxPriorityFeePerGas
          }
        : {
            gasPrice: feeData.gasPrice
          };

      let gasPrice;
      if (gasSettings.gasPrice) {
        gasPrice = gasSettings.gasPrice * 130n / 100n;
      } else {
        gasPrice = feeData.maxFeePerGas * 130n / 100n;
      }
      console.log('[DoubleMint] final gasPrice:', gasPrice.toString());

      showNotification('Minting NFTs...', 'Please wait while your NFTs are being minted.', true);
      tx = await nftContract.claimDoubleMint(tokenId, { gasPrice });
      const receipt = await tx.wait();

      if (receipt.status === 1) {
        hideNotification();
        showNotification('Mint Successful!', `Double mint claimed. Fetching NFT data...`, true);

        const minted = await extractMintedNFTs(receipt, nftContract);
        setMintedNFTs(minted);
        setCurrentNFTIndex(0);
        hideNotification();

        if (minted.length > 0) {
          setShowModal(true);
        } else {
          showNotification('Success', 'Double mint done, but no NFT data found.', false, true);
        }
      } else {
        hideNotification();
        showNotification('Transaction Failed', 'Raise gas limit and try again.', false, true);
      }
    } catch (error) {
      console.error('[DoubleMint] error:', error);
      hideNotification();
      showNotification('Transaction Failed', error.message || 'Please try again.', false, true);
    } finally {
      setMinting(false);
    }
  };

  const handleNext = () => {
    if (currentNFTIndex < mintedNFTs.length - 1) {
      setCurrentNFTIndex(currentNFTIndex + 1);
    }
  };

  const handleBack = () => {
    if (currentNFTIndex > 0) {
      setCurrentNFTIndex(currentNFTIndex - 1);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDropdownChange = (e) => {
    setContractAddress(e.target.value);
  };

  return (
    <div className="double-mint-section">
      <div className="input-with-dropdown">
        <select onChange={handleDropdownChange} className="custom-select">
          <option value="">Select Contract</option>
          <option value={dao3Address}>DAO3 Contract</option>
        </select>
      </div>
      <input
        type="text"
        placeholder="Token ID"
        value={tokenId}
        onChange={(e) => setTokenId(e.target.value)}
        className="custom-input"
      />
      <button className="custom-btn" onClick={claimDoubleMint} disabled={minting}>
        {minting ? 'Minting...' : 'CLAIM DOUBLE MINT'}
      </button>

      {notification.visible && (
        <Notification
          title={notification.title}
          message={notification.message}
          isLoading={notification.isLoading}
          onClose={hideNotification}
          showCloseButton={notification.showCloseButton}
        />
      )}

      {showModal && (
        <NFTModal
          nft={mintedNFTs[currentNFTIndex]}
          isFirstNFT={currentNFTIndex === 0}
          isLastNFT={currentNFTIndex === mintedNFTs.length - 1}
          onNext={handleNext}
          onBack={handleBack}
          onClose={handleClose}
        />
      )}
    </div>
  );
};

export default DoubleMintSection;













