// src/components/UpgradeFees.js
import React, { useEffect, useState } from 'react';
import { formatEther, Contract } from 'ethers';
import '../styles.css';
import DAO3_ABI from '../contracts/ETCMC-DAO3-ABI.json';

// addresses or logic
const dao3Address = "0x911211d3ebB4C61423d11a3bB6484d05333C576b";

const UpgradeFees = ({ readOnlyProvider }) => {
  const [fees, setFees] = useState([]);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const fetchUpgradeFees = async () => {
      if (!readOnlyProvider) return;
      try {
        // read-only contract
        const readOnlyContract = new Contract(dao3Address, DAO3_ABI, readOnlyProvider);

        // call a read method
        const [tiers, costs] = await readOnlyContract.AllUpgradeCosts(); 

        const tierNames = {
          1: "OPAL",
          2: "ORANGE",
          3: "BRONZE",
          4: "PINK",
          5: "CRYSTAL",
          6: "SILVER",
          7: "YELLOW",
          8: "RED",
          9: "GOLD",
          10: "TEAL",
          11: "PURPLE",
          12: "BLUE",
          13: "GREY",
          14: "BLACK"
        };

        const formattedFees = tiers.map((tier, i) => ({
          name: tierNames[tier],
          cost: formatEther(costs[i].toString()) // ETCPOW cost?
        }));

        setFees(formattedFees);
      } catch (error) {
        console.error('Failed to fetch upgrade fees:', error);
      }
    };

    fetchUpgradeFees();
  }, [readOnlyProvider]);

  if (!isVisible) return null;

  return (
    <div className="upgrade-fees">
      <button className="close-btn" onClick={() => setIsVisible(false)}>X</button>
      <h2>Upgrade Fees</h2>
      {fees.map((fee, idx) => (
        <p key={idx}>{fee.name}: {fee.cost} ETCPOW</p>
      ))}
    </div>
  );
};

export default UpgradeFees;







