// src/components/UpgradeSection.js

import React, { useState, useEffect } from 'react';
import { Contract, parseEther, Interface } from 'ethers';
import NFTModal from './NFTModal';
import Notification from './Notification';
import ETCPOW_ABI from '../contracts/ETCPOW-ABI.json';
import DAO3_ABI from '../contracts/ETCMC-DAO3-ABI.json';
import DAO2_ABI from '../contracts/ETCMC-DAO2-ABI.json';
import DAO1_ABI from '../contracts/ETCMC-DAO1-ABI.json';

const dao1Address = "0x1D606b6490510bBEbcADb256041dC50975C5E43D";
const dao2Address = "0xF88CBCE86c54d45B37aD514C7799d59Bb8C2Dd98";
const dao3Address = "0x911211d3ebB4C61423d11a3bB6484d05333C576b";
const tokenAddress = "0x6c3B413C461c42a88160Ed1B1B31d6f7b02a1C83"; // ETCPOW

function getABI(contractAddress) {
  switch (contractAddress.toLowerCase()) {
    case dao3Address.toLowerCase():
      return DAO3_ABI;
    case dao2Address.toLowerCase():
      return DAO2_ABI;
    case dao1Address.toLowerCase():
      return DAO1_ABI;
    default:
      throw new Error("Unsupported contract address");
  }
}

const UpgradeSection = ({ signer, readOnlyProvider }) => {
  const [nftContractAddress, setNftContractAddress] = useState('');
  const [tokenId, setTokenId] = useState('');
  const [upgradedNFT, setUpgradedNFT] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [notification, setNotification] = useState({
    visible: false,
    title: '',
    message: '',
    isLoading: false,
    showCloseButton: false
  });

  // If you want to display upgrade fees or any read calls, do them on readOnlyProvider:
  const [upgradeCosts, setUpgradeCosts] = useState([]); // example state

  useEffect(() => {
    // Use the readOnlyProvider for display data
    if (!readOnlyProvider) return;
    async function fetchUpgradeData() {
      try {
        // 1) read-only contract for reading
        const readOnlyDAO3 = new Contract(dao3Address, DAO3_ABI, readOnlyProvider);
        // e.g. AllUpgradeCosts
        const [tiers, costs] = await readOnlyDAO3.AllUpgradeCosts();

        // Map them if needed
        const tierNames = {
          1: "OPAL",
          2: "ORANGE",
          3: "BRONZE",
          4: "PINK",
          5: "CRYSTAL",
          6: "SILVER",
          7: "YELLOW",
          8: "RED",
          9: "GOLD",
          10: "TEAL",
          11: "PURPLE",
          12: "BLUE",
          13: "GREY",
          14: "BLACK"
        };
        const formatted = tiers.map((t, i) => ({
          name: tierNames[t],
          costWei: costs[i].toString()
        }));
        setUpgradeCosts(formatted);
      } catch (err) {
        console.error("Failed to fetch upgrade data (read-only):", err);
      }
    }
    fetchUpgradeData();
  }, [readOnlyProvider]);

  const showNotification = (title, message, isLoading = false, showCloseButton = false) => {
    setNotification({ visible: true, title, message, isLoading, showCloseButton });
  };

  const hideNotification = () => {
    setNotification({
      visible: false,
      title: '',
      message: '',
      isLoading: false,
      showCloseButton: false
    });
  };

  // -------------- TRANSACTION CODE --------------
  // Using the phone's signer. We forcibly set gasLimit => no estimateGas
  const upgradeNFT = async () => {
    if (!signer) {
      showNotification('Error', 'No wallet connected or signer is null.', false, true);
      return;
    }
    if (!nftContractAddress) {
      showNotification('Error', 'Please select a valid NFT contract.', false, true);
      return;
    }
    if (!tokenId) {
      showNotification('Error', 'Please enter a token ID.', false, true);
      return;
    }

    try {
      // 1) Approve ETCPOW spending: phone’s signer, but we set gasLimit manually
      const etcpowContract = new Contract(tokenAddress, ETCPOW_ABI, signer);

      showNotification('Approving ETCPOW...', 'Wait while your ETCPOW is approved.', true);
      let tx = await etcpowContract.approve(
        dao3Address,
        parseEther("10000"),
        {
          // Force a big gasLimit so no estimateGas call
          gasLimit: 600000n 
        }
      );
      await tx.wait();
      hideNotification();
      showNotification('ETCPOW Approved!', 'Now approve NFT transfer.', false, true);

      // 2) Approve NFT transfer
      const nftABI = getABI(nftContractAddress);
      const nftContract = new Contract(nftContractAddress, nftABI, signer);

      showNotification('Approving NFT Transfer...', 'Wait while your NFT is approved.', true);
      tx = await nftContract.approve(
        dao3Address,
        tokenId,
        {
          gasLimit: 300000n
        }
      );
      await tx.wait();
      hideNotification();
      showNotification('NFT Transfer Approved!', 'Please sign the Upgrade NFT transaction.', false, true);

      // 3) Actually upgrade
      const dao3Contract = new Contract(dao3Address, DAO3_ABI, signer);

      showNotification('Upgrading NFT...', 'Please wait...', true);
      tx = await dao3Contract.upgradeNFT(
        nftContractAddress,
        tokenId,
        {
          // maybe a bigger limit
          gasLimit: 600000n 
        }
      );
      const receipt = await tx.wait();

      if (receipt.status === 1) {
        hideNotification();
        showNotification('Upgrade Successful!', 'Fetching upgraded NFT data...', true);

        // Extract newTokenId
        const iface = new Interface(DAO3_ABI);
        let newTokenId;
        for (const log of receipt.logs) {
          try {
            const parsedLog = iface.parseLog(log);
            if (parsedLog.name === 'Upgrade') {
              newTokenId = parsedLog.args.newTokenId.toString();
              break;
            }
          } catch (err) {
            // not an Upgrade log
          }
        }

        if (!newTokenId) {
          throw new Error('Failed to find newTokenId in Upgrade logs.');
        }
        const tokenURI = await dao3Contract.tokenURI(newTokenId);
        const metadata = await fetch(tokenURI).then((res) => res.json());

        const upgradedNFTData = {
          tokenId: newTokenId,
          name: metadata.name,
          imageUrl: metadata.image,
          contractAddress: dao3Address
        };

        setUpgradedNFT(upgradedNFTData);
        hideNotification();
        setShowModal(true);
      } else {
        hideNotification();
        showNotification('Upgrade Failed', 'NFT upgrade failed. Try again.', false, true);
      }
    } catch (error) {
      console.error('Upgrade error:', error);
      hideNotification();
      showNotification('Upgrade Failed', error.message || 'Please try again.', false, true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  // Basic UI
  return (
    <div className="upgrade-section">
      
      {upgradeCosts.map((fee, idx) => (
        <p key={idx}>{fee.name}: {fee.costWei} wei</p>
      ))}

      <select
        className="custom-select"
        value={nftContractAddress}
        onChange={(e) => setNftContractAddress(e.target.value)}
      >
        <option value="">Select Contract</option>
        <option value={dao1Address}>DAO1 Contract</option>
        <option value={dao2Address}>DAO2 Contract</option>
        <option value={dao3Address}>DAO3 Contract</option>
      </select>
      <input
        type="text"
        placeholder="Token ID"
        value={tokenId}
        onChange={(e) => setTokenId(e.target.value)}
        className="token-id-input custom-input"
      />
      <button className="custom-btn" onClick={upgradeNFT}>
        UPGRADE NFT
      </button>

      {notification.visible && (
        <Notification
          title={notification.title}
          message={notification.message}
          isLoading={notification.isLoading}
          onClose={hideNotification}
          showCloseButton={notification.showCloseButton}
        />
      )}

      {showModal && upgradedNFT && (
        <NFTModal
          nft={upgradedNFT}
          isFirstNFT
          isLastNFT
          isUpgrade
          onClose={handleClose}
        />
      )}
    </div>
  );
};

export default UpgradeSection;










