// src/components/ETCWalletConnectButton.js
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import { ethers } from 'ethers';
import DAO3_ABI from '../contracts/ETCMC-DAO3-ABI.json';

const dao3Address = "0x911211d3ebB4C61423d11a3bB6484d05333C576b"; // DAO3 contract address

function ETCWalletConnectButton({ setWalletConnected, setSigner, setContract }) {
  const [wcConnected, setWcConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);

  async function connectWC() {
    try {
      // 1) Initialize the WalletConnect Provider for ETC (chainId=61)
      const ethereumProvider = await EthereumProvider.init({
        projectId: "3c3d5e4799f408949377e8dd8d5a3572", 
        chains: [61],
        rpcMap: {
          61: "https://etc.rivet.link"
        },
        showQrModal: true
      });

      // 2) Connect triggers the QR code / deep link flow
      const session = await ethereumProvider.connect();
      console.log("WC session:", session);

      // 3) Parse accounts
      let accounts = [];
      if (
        session &&
        session.namespaces &&
        session.namespaces.eip155 &&
        session.namespaces.eip155.accounts
      ) {
        accounts = session.namespaces.eip155.accounts.map(str => {
          const parts = str.split(":");
          return parts[2] || str;
        });
      }

      // fallback if empty
      if (!accounts.length) {
        const fallback = await ethereumProvider.request({ method: "eth_accounts" });
        accounts = fallback || [];
      }

      if (!accounts.length) {
        Swal.fire({
          icon: "error",
          title: "No Accounts",
          text: "Wallet did not return ETC accounts. Ensure chain 61 is active."
        });
        return;
      }

      const firstAddress = accounts[0];
      setWalletAddress(firstAddress);
      setWcConnected(true);

      // Mark wallet as connected
      setWalletConnected(true);

      // 5) Create an Ethers signer
      const wcEthersProvider = new ethers.BrowserProvider(ethereumProvider);
      const signer = await wcEthersProvider.getSigner();
      console.log("ETC signer address:", await signer.getAddress());
      setSigner(signer);

      // 6) Optionally create the DAO3 contract with that signer
      const contract = new ethers.Contract(dao3Address, DAO3_ABI, signer);
      setContract(contract);

      Swal.fire({
        icon: "success",
        title: "WalletConnect (ETC) Connected",
        text: `Address: ${firstAddress}`
      });
    } catch (err) {
      console.error("WalletConnect ETC error:", err);
      Swal.fire({
        icon: "error",
        title: "Connection Failed",
        text: err.message || String(err)
      });
    }
  }

  return (
    <div className="wallet-connect">
      {wcConnected ? (
        <p>WC ETC: {walletAddress}</p>
      ) : (
        <button className="custom-btn" onClick={connectWC}>
          Use Wallet Connect (WC)
        </button>
      )}
    </div>
  );
}

export default ETCWalletConnectButton;


