// src/components/MetaMaskConnectButton.js
import React, { useState } from 'react';
import { ethers } from 'ethers';
import DAO3_ABI from '../contracts/ETCMC-DAO3-ABI.json';

const dao3Address = "0x911211d3ebB4C61423d11a3bB6484d05333C576b"; // DAO3 contract address

function MetaMaskConnectButton({ setWalletConnected, setSigner, setContract }) {
  const [walletAddress, setWalletAddress] = useState(null);

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();

        setWalletAddress(address);
        setWalletConnected(true);
        setSigner(signer);

        // Initialize the contract and set it in state
        const contract = new ethers.Contract(dao3Address, DAO3_ABI, signer);
        setContract(contract);

      } catch (error) {
        console.error('Wallet connection failed', error);
      }
    } else {
      alert('Please install MetaMask!');
    }
  };

  return (
    <div className="wallet-connect">
      {walletAddress ? (
        <p>Connected: {walletAddress}</p>
      ) : (
        <button className="custom-btn" onClick={connectWallet}>
          Connect Wallet
        </button>
      )}
    </div>
  );
}

export default MetaMaskConnectButton;



